<template>
  <v-container fill-height>
    <v-row no-gutters>
      <v-col cols="12"
        ><div class="white--text text-center text-h1 h1-text">
          403
        </div></v-col
      >
      <v-col cols="12"
        ><div class="white--text text-center text-h4 font-weight-bold h2-text">
          Forbidden Access :(
        </div></v-col
      >
      <v-col cols="12"
        ><div class="white--text text-center text-h6 h2-text">
          Ooooups! Looks like you don't have access.
        </div></v-col
      >
    </v-row>
  </v-container>
</template>
<script>
import { firebase } from "@/firebase";
export default {
  mounted() {
    this.$store.commit("setUser", null);
    firebase.auth().signOut();
    setTimeout(() => this.$router.push({ name: "login" }), 6000);
  },
};
</script>
<style scoped>
.h1-text {
  font-size: 8rem !important;
  letter-spacing: 14px !important;
  font-weight: 700 !important;
  margin-bottom: 25px !important;
}
.h2-text {
  margin-top: 0 !important;
  margin-bottom: 0.5rem !important;
}
</style>
